<template>
  <div class="viewer">
    <form action="/">
      <van-search
          v-model="busgrp"
          show-action
          placeholder="请输入搜索关键词"
          @search="onSearch"
      >
        <div slot="action" @click="onClick">搜索</div>
      </van-search>
    </form>
    <div class="image">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <van-image
              width="100%"
              height="100%"
              fit="contain"
              :src="require('@/assets/'+image)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-grid square :gutter="5" >
      <van-grid-item
          v-for="value in busgrpTable"
          :key="value.busgrp"
          :text="value.busgrp"
          @click="to(value)"
          gutter
      >
        <template #icon>
          <van-icon class-prefix="icon" :name="value.icon" color="#1597DC"
                    size="40"/>
        </template>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import {getGroupInfo, getFileInfoByGrp} from '@/api/fileViewer'
import {Toast} from "vant";

export default {
  name: "FileViewer",
  components: {},
  data() {
    return {
      busgrp: '',
      busgrpTable: [],
      value: '',
      images: [
        // 'scene.jpg',
        // 'coal.jpg',
        // 'food.jpg',
        '1.png',
        '1.png',
      ],
    }
  },
  watch: {
    // 'busgrp': function () {
    //   this.busgrpTable = [];
    //   this.onSearch();
    // },
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getGroupInfo({
        userNo: this.$store.getters.userId,
        busgrp: this.busgrp
      }).then(res => {
        console.log(res)
        this.busgrpTable = res
        if(res.length>0){
          Toast.success('加载成功!');
        }else{
          Toast('暂无相关组办！');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    onCancel() {
    },
    onClick(){
      this.$router.push({path: '/FileDetailOne/'+this.busgrp})
    },
    to(item){
      this.$router.push({path: '/FileDetail/'+item.busgrp})
    }
  }
}
</script>

<style scoped>
.viewer{
  background-color: #FFFFFF;
}
</style>